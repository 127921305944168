/* إعادة تعريف العناصر الجذرية لتأمين تجربة استخدام سلسة */
body, html {
    margin: 0;
    padding: 0;
    height: 100%; /* يجعل الجسم والأتش تي أم أل يمتلكان ارتفاعًا كاملاً */
    overflow: auto;
}

/* منع تدوير الشاشة على الهواتف */
@media screen and (max-width: 768px) {
    body {
        orientation: portrait;
    }
}

/* تحسين الهيكلية لسهولة القراءة وتوسيع مركز التحكم */
.radio-player-container {
    position: relative; /* تحديد الوضع ليساعد على وضع العناصر الأخرى بشكل صحيح */
    width: 100%;
    min-height: 100vh; /* استخدام min-height بدلاً من height للسماح بالتمدد */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* للتحكم في ظهور شريط التمرير */
}

/* لتحسين الهيكلية والتنسيق وتقليل المسافات */
.lottie-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -26px; /* تقليل المسافة بين Lottie والـ ReactPlayer */
    margin-top: 75px; /* الازاحة للأسفل */
    height: 475px;
    width: 475px;
}

/* للأجهزة المحمولة */
@media screen and (max-width: 766px) {
    .lottie-container {
        margin: 0; /* تعطيل التباعد الإضافي */
        margin-top: 98px; /* تحديد الازاحة للأسفل بشكل مختلف للهواتف المحمولة */
        height: 300px; /* تحديد ارتفاع مختلف للهواتف المحمولة */
        width: 65%; /* تحديد عرض مختلف للهواتف المحمولة */
    }
}

/* للأجهزة ذات عرض شاشة أقل من 576 بكسل */
@media screen and (max-width: 375px) {
    .lottie-container {
        margin: 0;
        margin-top: 95px;
        height: 270px;
        width: 75%;
    }
}

/* للأجهزة ذات عرض شاشة بين 576 بكسل و 768 بكسل */
@media screen and (min-width: 576px) and (max-width: 767px) {
    .lottie-container {
        margin: 0;
        margin-top: 98px;
        height: 350px;
        width: 50%;
    }
}

/* للأجهزة ذات عرض شاشة بين 768 بكسل و 992 بكسل */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .lottie-container {
        margin: 0;
        margin-top: 95px;
        height: 650px;
        width: 70%;
    }
}

/* للأجهزة ذات عرض شاشة بين 992 بكسل و 1200 بكسل */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .lottie-container {
        margin: 0;
        margin-top: 95px;
        height: 650px;
        width: 55%;
    }
}

/* للأجهزة ذات عرض شاشة أكبر من 1200 بكسل */
@media screen and (min-width: 1200px) and (max-width: 1499px) {
    .lottie-container {
        margin: 0;
        margin-top: 100px;
        height: 660px;
        width: 45%;
    }
}

@media screen and (min-width: 1500px) {
    .lottie-container {
        margin: 0;
        margin-top: 100px;
        height: 450px;
        width: 34%;
    }
}

.lottie-container img {
    object-fit: cover; /* يحدد كيفية تناسب الصورة داخل العنصر */
}

/* لتحسين التنسيق وإزالة الحاجة للعناصر المطلقة */
.play-pause-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: absolute;
    bottom: 10px;
}

.icon-container {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 75px;
}

.icon-container svg {
    color: #333;
    font-size: 24px;
}

/* لتحسين هيكلية وتنظيم الخلفيات */
.background-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    z-index: -1;
}

.background-container.web-background {
    background-image: url('/src/assets/img/web-background.jpg');
}

.background-container.mobile-background {
    background-image: url('/src/assets/img/mobile-background.webp');
}

@media screen and (max-width: 768px) {
    /* لوضع الموبايل */
    .background-container.web-background {
        display: none; /* إخفاء الخلفية في وضع الموبايل */
    }

    .background-container.mobile-background {
        display: block; /* عرض الخلفية في وضع الموبايل */
    }
}

@media screen and (min-width: 769px) {
    /* لوضع الويب */
    .background-container.mobile-background {
        display: none; /* إخفاء الخلفية في وضع الويب */
    }

    .background-container.web-background {
        display: block; /* عرض الخلفية في وضع الويب */
    }
}

/* تطبيق التعديلات على جميع العناصر */
* {
    animation: your-animation-name 1s infinite; /* استبدال "your-animation-name" بالاسم الذي تريده */
}

@keyframes your-animation-name {
    /* تعريف الرسوم المتحركة هنا */
}

.current-time.web {
    color: #ffffff;
    font-size: 30px;
    font-weight: 600;
    margin: -20px;
}
@media screen and (max-width: 768px) {
    .current-time {
        position: fixed;
        top: 20px;
        left: 62%; /* تعديل القيمة لنقل العنصر إلى اليمين قليلاً */
        transform: translateX(-50%);
        z-index: 999;
        font-size: 14px; /* تعديل حجم الخط */
    }
}

